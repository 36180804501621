import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { OrderFormService } from '../order-form.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MatDialog } from '@angular/material/dialog';
import { CheckoutDialogComponent } from '../checkout-dialog/checkout-dialog.component';
import { OrderDetailsDialogComponent } from '../order-details-dialog/order-details-dialog.component';
import { CouponDialogComponent } from "../coupon-dialog/coupon-dialog.component";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ClosedComponent } from '../closed/closed.component';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { OrderTimeDetailsComponent } from '../order-time-details/order-time-details.component';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {


  otherTipAmount: number;
  tip;
  user;
  loading;
  valueShow;
  defaultTip;
  pop = true;

  constructor(private _snackBar: MatSnackBar, public router: Router,
    private afs: AngularFirestore, public afAuth: AngularFireAuth, private functions: AngularFireFunctions,
    public dialog: MatDialog, public orderService: OrderFormService) {
    this.afAuth.user.subscribe(val => {
      this.user = val;
    });
    this.orderService.tipPercent.valueOf
    if (this.orderService.orderObject.get('orderType').value != 'Doordash') {
      this.defaultTip = ".15"
    }

    else {
      this.defaultTip = ".20"
    }



  }



  ngOnInit() {
    this.afs.doc('restaurants/' + this.orderService.restaurant.restaurantID).valueChanges().subscribe((doc: any) => {
      this.orderService.checkOpen();
      if (!this.orderService.canOrderNow) {
        if (this.orderService.restaurant.acceptFutureOrders) {
          const dialogRef = this.dialog.open(OrderDetailsDialogComponent, {
            width: '90vw',
            maxWidth: '700px',
            disableClose: true,
          });
        } else {
          let openningMsg = "We will start accepting order at " + this.orderService.earliest_OrderDateTime.format("LLLL")
          const dialogRef = this.dialog.open(ClosedComponent, {
            width: '90vw',
            maxWidth: '700px',
            disableClose: true,
            data: { closedMessage: openningMsg }
          });
        }

      }
      // const dialogRef = this.dialog.open(OrderDetailsDialogComponent, {
      //   width: '90vw',
      //   maxWidth: '700px',
      //   disableClose: true,
      // });
      if (doc.active == false) {
        const dialogRef = this.dialog.open(ClosedComponent, {
          width: '90vw',
          maxWidth: '700px',
          disableClose: true,
          data: { closedMessage: doc.closedMessage }
        });
      }
    });
    this.valueShow = this.orderService.tipPercent.toString()
    this.otherTipAmount = this.orderService.tipPercent * 100

  }

  removeItem(item): void {
    this.orderService.removeFromCart(item);
  }



  async openPaymentDialog(e) {
    // console.log('orderObject before open the dialog: ',this.orderService.orderObject.value)

    e.preventDefault();

    this.orderService.orderObject.get('items').updateValueAndValidity({ onlySelf: true, emitEvent: true })
    this.orderService.orderObject.get('coupons').updateValueAndValidity({ onlySelf: true, emitEvent: true })
    if (this.orderService.orderObject.invalid) {
      return;
    }


    const dialogRef = this.dialog.open(CheckoutDialogComponent, {
      width: '100vw',
      maxWidth: '500px',
      height: "auto",
      disableClose: true,
      data: { amount: this.orderService.orderObject.get('total').value }
    });

  }

  tipChange(val) {
    console.log('tip change', val);
    this.valueShow = val
    if (val == "other") {
      console.log('this.otherTipAmount', this.otherTipAmount);
      if (this.otherTipAmount < 0) {
        this._snackBar.open('Tip cannot be below Zero. ', 'Ok 😅', {
          duration: 10000,
        });
        return;
      }
      this.orderService.tipPercent = this.otherTipAmount / 100;

    } else {
      let newVal = parseFloat(val);
      // ////console.log(newVal);
      this.orderService.tipPercent = newVal;
    }

    this.orderService.calculateTotal();
  }
  openCouponDialog() {
    const dialogRef = this.dialog.open(CouponDialogComponent, {
      width: '90vw',
      maxWidth: '700px',
      autoFocus: false,
    });
  }
  openOrderDetails(orderType: string) {
    this.orderService.orderObject.patchValue({ 'orderType': orderType })
    const dialogRef = this.dialog.open(OrderDetailsDialogComponent, {
      width: '100%',
      maxWidth: '700px',
      autoFocus: false,
    });
  }
  openTimeDetails() {
    console.log('opening time...')
    const dialogRef2 = this.dialog.open(OrderTimeDetailsComponent, {
      width: '100%',
      maxWidth: '700px',
      autoFocus: false,
    });
  }

  createStripeSession() {

  }

}
