<div class="toolbar">
    <img height="50px" src="../../assets/images/inhouse orders logo.png" alt="">
</div>
<div class="container">
    <h2>Page Not Found</h2>
    <h3>Were you looking for one of these restaurants?</h3>
    <div class="restaurantCard" *ngFor="let r of restaurants">
        <img [src]="r.imageLinks.logo" width="150px" [alt]="r.displayName + ' Logo'">
        <div class="restaurantInfo">
            <p class="name">{{r.displayName}}</p>
            <p class="address">{{r.address}}</p>
            <a [href]="'https://ordernow.inhouseorders.io/' + r.restaurantID">View Restaurant</a>
        </div>
    </div>
</div>